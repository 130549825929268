import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import VectorLayer from 'ol/layer/Vector.js';
import TileLayer from 'ol/layer/Tile.js';
import VectorSource from 'ol/source/Vector.js';
import View from 'ol/View.js';
import { OSM } from 'ol/source.js';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style.js';
import XYZ from 'ol/source/XYZ';
import { useGeographic } from 'ol/proj';
import obj from './markers.json';
import OLCesium from 'olcs';

const titleLayer = new TileLayer({
   source: new XYZ({
     url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
   })
 // source: new OSM(),
})
// useGeographic();
const image = new CircleStyle({
  radius: 50,
  fill: null,
  stroke: new Stroke({ color: 'red', width: 1 }),
});

const styles = {
  'Point': new Style({
    stroke: new Stroke({
      color: 'yellow',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 0, 0.1)',
    }),
  }),
  'LineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  'MultiLineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  'MultiPoint': new Style({
    image: image,
  }),
  'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'yellow',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 0, 0.1)',
    }),
  }),
  'Polygon': new Style({
    stroke: new Stroke({
      color: 'blue',
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
  'GeometryCollection': new Style({
    stroke: new Stroke({
      color: 'magenta',
      width: 2,
    }),
    fill: new Fill({
      color: 'magenta',
    }),
    image: new CircleStyle({
      radius: 10,
      fill: null,
      stroke: new Stroke({
        color: 'magenta',
      }),
    }),
  }),
  'Circle': new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(255,0,0,0.2)',
    }),
  }),
};

const styleFunction = function (feature) {
  return styles[feature.getGeometry().getType()];
};


const geojsonObject = {
  'type': 'FeatureCollection',
  'crs': {
    'type': 'name',
    'properties': {
      'name': 'EPSG:3857',
    },
  },
  'features': [
    {
      'type': 'Feature',
      'properties': {
        "name": "Mountain Hideaway",
        "category": "pexels",
        "image_url": "https://www.pexels.com/photo/scenic-view-of-mountain-during-golden-hour-258335/"
      },
      'geometry': {
        'type': 'Point',
        'coordinates': [4e6, -2e6],
      },
    },
    {
      'type': 'Feature',
      'properties': {

        "name": "Riverside Cabin",
        "category": "pexels",
        "image_url": "https://www.pexels.com/photo/photo-of-house-near-river-2260476/"

      },
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [4e6, -2e6],
          [8e6, 2e6],
        ],
      },
    },
    {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [4e6, 2e6],
          [8e6, -2e6],
        ],
      },
    },
    {
      'type': 'Feature',
      'properties': {
        "name": "Mountain Hideaway",
        "category": "pexels",
        "image_url": "https://www.pexels.com/photo/scenic-view-of-mountain-during-golden-hour-258335/"
      },
      'geometry': {
        'type': 'Polygon',
        'coordinates': [
          [
            [-5e6, -1e6],
            [-3e6, -1e6],
            [-4e6, 1e6],
            [-5e6, -1e6],
          ],
        ],
      },
    },
    {
      'type': 'Feature',
      'properties': {
        "name": "Mountain Hideaway",
        "category": "pexels",
        "image_url": "https://www.pexels.com/photo/scenic-view-of-mountain-during-golden-hour-258335/"
      },
      'geometry': {
        'type': 'MultiLineString',
        'coordinates': [
          [
            [-1e6, -7.5e5],
            [-1e6, 7.5e5],
          ],
          [
            [1e6, -7.5e5],
            [1e6, 7.5e5],
          ],
          [
            [-7.5e5, -1e6],
            [7.5e5, -1e6],
          ],
          [
            [-7.5e5, 1e6],
            [7.5e5, 1e6],
          ],
        ],
      },
    },
    {
      'type': 'Feature',
      'properties': {
        "name": "Mountain Hideaway",
        "category": "pexels",
        "image_url": "https://www.pexels.com/photo/scenic-view-of-mountain-during-golden-hour-258335/"
      },
      'geometry': {
        'type': 'MultiPolygon',
        'coordinates': [
          [
            [
              [-5e6, 6e6],
              [-3e6, 6e6],
              [-3e6, 8e6],
              [-5e6, 8e6],
              [-5e6, 6e6],
            ],
          ],
          [
            [
              [-2e6, 6e6],
              [0, 6e6],
              [0, 8e6],
              [-2e6, 8e6],
              [-2e6, 6e6],
            ],
          ],
          [
            [
              [1e6, 6e6],
              [3e6, 6e6],
              [3e6, 8e6],
              [1e6, 8e6],
              [1e6, 6e6],
            ],
          ],
        ],
      },
    },
    {
      'type': 'Feature',
      'properties': {
        "name": "Mountain Hideaway",
        "category": "pexels",
        "image_url": "https://www.pexels.com/photo/scenic-view-of-mountain-during-golden-hour-258335/"
      },
      'geometry': {
        'type': 'GeometryCollection',

        'geometries': [
          {
            'type': 'LineString',
            'coordinates': [
              [-5e6, -5e6],
              [0, -5e6],
            ],
          },
          {
            'type': 'Point',
            'coordinates': [4e6, -5e6],
          },
          {
            'type': 'Polygon',
            'coordinates': [
              [
                [1e6, -6e6],
                [3e6, -6e6],
                [2e6, -4e6],
                [1e6, -6e6],
              ],
            ],
          },
        ],
      },
    },
  ],
};


var vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(geojsonObject),
})

const vectorLayer = new VectorLayer({
  source: vectorSource,
  style: styleFunction,
});

const map = new Map({
  layers: [titleLayer, vectorLayer],
  target: 'map',
  view: new View({
    center: [-9307479.38481531, 1094788.4993520812],
    zoom: 4,
  }),
});

const displayFeatureInfo = function (pixel) {
  vectorLayer.getFeatures(pixel).then(function (features) {
    const feature = features.length ? features[0] : undefined;
    const sidebar = document.getElementById('sidebar');
    if (features.length) {
      console.log(sidebar)
      // info.innerHTML = feature.get('ECO_NAME') + ': ' + feature.get('NNH_NAME');
      var featureProperties = feature.getProperties();
      // Open the modal with feature properties
      document.getElementById('modal-content').innerHTML = JSON.stringify(featureProperties);
      openModal(featureProperties);

    } else {
      console.log(document.getElementById('sidebar').classList.contains("closed"))
      if (document.getElementById('sidebar').classList.contains("closed") == false) {
        document.getElementById('sidebar').classList.add('closed');
      }
    }
  });
};
const ol3d = new OLCesium({ map: map });
ol3d.setEnabled(true);
map.on('click', function (evt) {
  displayFeatureInfo(evt.pixel);
});

function openModal(featureProperties) {
  console.log("Feature clicked:", featureProperties);
  if (document.getElementById('sidebar').classList.contains("closed") == true) {
    document.getElementById('sidebar').classList.remove('closed');
  }
}


map.on('click', function(event) {

  var coordinate = event.coordinate; // Get the clicked coordinate
  console.log('Clicked coordinate:', event);
  
  // You can perform further actions here, such as adding a marker or triggering an event.
});